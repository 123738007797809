<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <router-link :to="'/leveeDeFonds'">
          Dettes bancaires>
        </router-link>
        <span>Création de dette bancaire</span>
      </div>
    </div>
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Ajouter une dette bancaire
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <form class="accordion" 
          id="accordionExample">
      <table  class="table col-md-8 mx-auto">
        <thead class="card-header" 
               id="headingOne">
          <tr class="tht-blue"
              data-toggle="collapse" 
              data-target="#collapseOne" 
              aria-expanded="true" 
              aria-controls="collapseOne">
            <th class="pl-3"> Source de la dette</th>
          </tr>
        </thead>
        <div id="collapseOne" 
             class="collapse show" 
             aria-labelledby="headingOne" 
             data-parent="#accordionExample">
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-md-5"
                   id="base"
                   ref="base">
                <label>Base</label>
              </div>
              <div class="form-group col-md-6"
                   id="base"
                   ref="base">
                <select class="form-control" 
                        name="" 
                        id=""
                        v-on:click="removeSPan('base')"
                        v-model="form.base">
                  <option value="SIEGE">SIEGE</option>
                  <option value="PEAGE">PEAGE</option>
                  <option value="PESAGE">PESAGE</option>
                  <option value="AIRE_STATIONNEMENT">AIRE DE STATIONNEMENT</option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-5">
                <label class="req">Nom du preteur</label>
              </div>  
              <div class="form-group col-md-6">
                <v-select :options="listDesBanques"
                          :reduce="preteur => preteur.id"
                          label="sigle"
                          ref="preteur"
                          v-model="form.preteur"
                          @input="removeSPan('preteur')"
                          placeholder="Choisir une preteur">
                  <span slot="no-options"
                        @click="$refs.preteur.open = false"
                        class="text-danger">
                    Aucune Banque trouvée
                  </span>

                </v-select>
                <span v-if="form.errors().has('preteur') && affiche.preteur !== 'preteur'"
                      v-text="form.errors().get('preteur')"
                      class="errorMsg"> </span>
              </div>
            </div>
          </div>
        </div>
        <thead class="card-header" 
               id="headingTwo">
          <tr class="tht-blue"
              data-toggle="collapse" 
              data-target="#collapseTwo" 
              aria-expanded="true" 
              aria-controls="collapseTwo">
            <th class="pl-3"> Informations de la dette</th>
          </tr>
        </thead>
        <div id="collapseTwo" 
             class="collapse show" 
             aria-labelledby="headingTwo" 
             data-parent="#accordionExample">
          <div class="card-body">
            <div class="form-row">
              <div class="form-group col-md-5"
                   id="nature_dette"
                   ref="nature_dette">
                <label>Nature dette</label>
              </div>
              <div class="form-group col-md-6"
                   id="nature_dette"
                   ref="nature_dette">
                <select class="form-control" 
                        name="" 
                        id=""
                        v-on:click="removeSPan('nature_dette')"
                        v-model="form.nature_dette">
                  <option value="Levée de fonds">Levée de fonds</option>
                  <option value="Levée de fonds (Court terme)">Levée de fonds (Court terme)</option>
                  <option value="Levée de fonds (Moyen terme)">Levée de fonds (Moyen terme)</option>
                  <option value="Crédit relais">Crédit relais</option>
                  <option value="Crédit (Court terme)">Crédit (Court terme)</option>
                  <option value="Crédit (Moyen terme)">Crédit (Moyen terme)</option>
                  <option value="Facilité (Court terme)">Facilité (Court terme)</option>
                  <option value="Facilité (Moyen terme)">Facilité (Moyen terme)</option>
                  <option value="Traites avalisées">Traites avalisées</option>
                </select>
                <span v-if="form.errors().has('nature_dette') && affiche.nature_dette !== 'nature_dette'"
                      v-text="form.errors().get('nature_dette')"
                      class="errorMsg"> </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-5">
                <label>Objet de la dette</label>
              </div>
              <div class="form-group col-md-6">
                <input type="text"
                       class="form-control"
                       v-model="form.objet_dette"
                       aria-describedby="helpId"
                       v-on:click="removeSPan('objet_dette')"
                       placeholder="objet de la dette" 
                       autocomplete="off"/>
                <span v-if="form.errors().has('objet_dette') && affiche.objet_dette !== 'objet_dette'"
                      v-text="form.errors().get('objet_dette')"
                      class="errorMsg"> </span>
              </div>
            </div>
            <div class="form-row">

              <div class="form-group col-md-5">
                <label>Montant du prêt</label>
              </div>
              <div class="form-group col-md-6">
                <input type="text"
                       class="form-control"
                       v-model="montant_pret"
                       @input="addSpace('montant_pret')"
                       aria-describedby="helpId"
                       v-on:click="removeSPan('montant_pret')"
                       placeholder=" 1000" 
                       autocomplete="off"/>
                <span v-if="form.errors().has('montant_pret') && affiche.montant_pret !== 'montant_pret'"
                      v-text="form.errors().get('montant_pret')"
                      class="errorMsg"> </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-5">
                <label>Montant des tirages</label>
              </div>
              <div class="form-group col-md-6">
                <input type="text"
                       class="form-control"
                       v-model="montant_tirages"
                       aria-describedby="helpId"
                       @input="addSpace('montant_tirages')"
                       v-on:click="removeSPan('montant_tirages')"
                       placeholder="1000" 
                       autocomplete="off"/>
                <span v-if="form.errors().has('montant_tirages') && affiche.montant_tirages !== 'montant_tirages'"
                      v-text="form.errors().get('montant_tirages')"
                      class="errorMsg"> </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-5">
                <label class="req">Taux d'intérêt</label>
              </div>
              <div class="form-group col-md-6">
                <input type="number"
                       class="form-control"
                       name=""
                       v-model="form.taux_interet"
                       aria-describedby="helpId"
                       v-on:click="removeSPan('taux_interet')"
                       placeholder="2.3" 
                       max="100"/>
                <span v-if="form.errors().has('taux_interet') && affiche.taux_interet !== 'taux_interet'"
                      v-text="form.errors().get('taux_interet')"
                      class="errorMsg"> </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-5">
                <label class="req">Date mise en place</label>
              </div>
              <div class="form-group col-md-6">
                <input type="date"
                       class="form-control"
                       name=""
                       v-model="form.date_mise_place"
                       aria-describedby="helpId"
                       v-on:click="removeSPan('date_mise_place')"
                       placeholder="" />
                <span v-if="form.errors().has('date_mise_place') && affiche.date_mise_place !== 'date_mise_place'"
                      v-text="form.errors().get('date_mise_place')"
                      class="errorMsg"> </span>
              </div>
            </div>
            <div class="form-row">
            
              <div class="form-group col-md-5">
                <label class="req">Date fin remboursement</label>
              </div>
              <div class="form-group col-md-6">
                <input type="date"
                       class="form-control"
                       name=""
                       v-model="form.date_fin_remboursement"
                       aria-describedby="helpId"
                       v-on:click="removeSPan('date_fin_remboursement')"
                       placeholder="" />
                <span
                  v-if="form.errors().has('date_fin_remboursement') && affiche.date_fin_remboursement !== 'date_fin_remboursement'"
                  v-text="form.errors().get('date_fin_remboursement')"
                  class="errorMsg"></span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-5">
                <label >Garanties accordées</label>
              </div>
              <div class="form-group col-md-6">
                <select class="form-control" 
                        name="" 
                        id=""
                        v-model="form.garantie_accordee">
                  <option value="">Choisissez une option</option>
                  <option value="OUI">Oui</option>
                  <option value="NON">Non</option>
                </select>

                <span v-if="form.errors().has('garantie_accordee') && affiche.garantie_accordee !== 'garantie_accordee'"
                      v-text="form.errors().get('garantie_accordee')"
                      class="errorMsg"> </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-5">
                <label >Nature de la garantie</label>
              </div>
              <div class="form-group col-md-6">
                <input type="text"
                       class="form-control"
                       name=""
                       v-model="form.nature_garantie"
                       aria-describedby="helpId"
                       v-on:click="removeSPan('nature_garantie')" 
                       autocomplete="off"/>
                <span v-if="form.errors().has('nature_garantie') && affiche.nature_garantie !== 'nature_garantie'"
                      v-text="form.errors().get('nature_garantie')"
                      class="errorMsg"> </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-5">
                <label >Garantie nécessaire</label>
              </div>
              <div class="form-group col-md-6">
                <input type="text"
                       class="form-control"
                       name=""
                       v-model="garantie_necessaire"
                       @input="addSpace('garantie_necessaire')"
                       aria-describedby="helpId"
                       v-on:click="removeSPan('garantie_necessaire')" 
                       autocomplete="off"/>
                <span v-if="form.errors().has('garantie_necessaire') && affiche.garantie_necessaire !== 'garantie_necessaire'"
                      v-text="form.errors().get('garantie_necessaire')"
                      class="errorMsg"> </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-5">
                <label >Garantie échue</label>
              </div>
              <div class="form-group col-md-6">
                <input type="text"
                       class="form-control"
                       name=""
                       v-model="echeance_echue"
                       @input="addSpace('echeance_echue')"
                       aria-describedby="helpId"
                       v-on:click="removeSPan('echeance_echue')" 
                       autocomplete="off"/>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-5">
                <label >Date de restitution de la Garantie</label>
              </div>
              <div class="form-group col-md-6">
                <input type="date"
                       class="form-control"
                       name=""
                       v-model="form.date_echeance_echue"/>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-5">
                <label class="req">Périodicité</label>
              </div>
              <div class="form-group col-md-6">
                <select class="form-control"
                        name=""
                        v-on:click="removeSPan('periodicite')"
                        v-model="form.periodicite">
                  <option value="">Choisissez une option</option>
                  <option value="HEBDOMADAIRE">Hebdomadaire</option>
                  <option value="MENSUEL">Mensuelle</option>
                  <option value="TRIMESTRIEL">Trimestrielle</option>
                  <option value="QUADRIMESTRE">Quadrimestrielle</option>
                  <option value="SEMESTRIEL">Semestrielle</option>
                  <option value="ANNUEL">Annuelle</option>
                </select>
              
                <span v-if="form.errors().has('periodicite') && affiche.periodicite !== 'periodicite'"
                      v-text="form.errors().get('periodicite')"
                      class="errorMsg"> </span>
              </div>
            
            </div>
            <div class="form-row">
              <div class="form-group col-md-5">
                <label class="req">Durée de remboursement</label>
              </div>
              <div class="form-group col-md-6">
                <input type="number"
                       class="form-control"
                       name=""
                       v-model="form.duree_remboursement"
                       aria-describedby="helpId"
                       v-on:click="removeSPan('duree_remboursement')"
                       placeholder="5" />
                <span v-if="form.errors().has('duree_remboursement') && affiche.duree_remboursement !== 'duree_remboursement'"
                      v-text="form.errors().get('duree_remboursement')"
                      class="errorMsg"> </span>
              </div>
            </div>
          </div>
        </div>
        <thead class="card-header" 
               id="headingThree">
          <tr class="tht-blue"
              data-toggle="collapse" 
              data-target="#collapseThree" 
              aria-expanded="true" 
              aria-controls="collapseThree">
            <th class="pl-3"> Différés de la dette</th>
          </tr>
        </thead>
        <div id="collapseThree" 
             class="collapse show" 
             aria-labelledby="headingThree" 
             data-parent="#accordionExample">
          <div class="card-body">
            <div class="form-row row">
              <div class="form-group col-md-5">
                <label>Différé en principal</label>
              </div>
              <div class="form-group col-md-6">
                <select class="form-control" 
                        name="" 
                        id=""
                        v-model="form.differe_en_principal">
                  <option value="">Choisissez une option</option>
                  <option value="OUI">Oui</option>
                  <option value="NON">Non</option>
                </select>
                <span v-if="form.errors().has('differe_en_principal') && affiche.differe_en_principal !== 'differe_en_principal'"
                      v-text="form.errors().get('differe_en_principal')"
                      class="errorMsg"> </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-5">
                <label>Période différée en principal</label>
              </div>
              <div class="form-group col-md-6">
                <input type="number"
                       class="form-control"
                       name=""
                       v-model="form.periode_differe_en_principal"
                       aria-describedby="helpId"
                       v-on:click="removeSPan('periode_differe_en_principal')"
                       placeholder="5" />
                <span v-if="form.errors().has('periode_differe_en_principal') && affiche.periode_differe_en_principal !== 'periode_differe_en_principal'"
                      v-text="form.errors().get('periode_differe_en_principal')"
                      class="errorMsg"> </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-5">
                <label>Différé en intérêt</label>
              </div>
              <div class="form-group col-md-6">
                <select class="form-control" 
                        name="" 
                        id=""
                        v-model="form.differe_en_interet">
                  <option value="">Choisissez une option</option>
                  <option value="OUI">Oui</option>
                  <option value="NON">Non</option>
                </select>
                <span v-if="form.errors().has('differe_en_interet') && affiche.differe_en_interet !== 'differe_en_interet'"
                      v-text="form.errors().get('differe_en_interet')"
                      class="errorMsg"> </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-5">
                <label>Période différée en intérêt</label>
              </div>
              <div class="form-group col-md-6">
                <input type="number"
                       class="form-control"
                       name=""
                       v-model="form.periode_differe_en_interet"
                       aria-describedby="helpId"
                       v-on:click="removeSPan('periode_differe_en_interet')"
                       placeholder="5" />
                <span v-if="form.errors().has('periode_differe_en_interet') && affiche.periode_differe_en_interet !== 'periode_differe_en_interet'"
                      v-text="form.errors().get('periode_differe_en_interet')"
                      class="errorMsg"> </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-5">
                <label>Différé en assurance</label>
              </div>
              <div class="form-group col-md-6">
                <select class="form-control" 
                        name="" 
                        id=""
                        v-model="form.differe_en_assurence">
                  <option value="">Choisissez une option</option>
                  <option value="OUI">Oui</option>
                  <option value="NON">Non</option>
                </select>
                <span v-if="form.errors().has('differe_en_assurence') && affiche.differe_en_assurence !== 'differe_en_assurence'"
                      v-text="form.errors().get('differe_en_assurence')"
                      class="errorMsg"> </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-5">
                <label>Période différée en assurance</label>
              </div>
              <div class="form-group col-md-6">
                <input type="number"
                       class="form-control"
                       name=""
                       v-model="form.periode_differe_en_assurance"
                       aria-describedby="helpId"
                       v-on:click="removeSPan('periode_differe_en_assurance')"
                       placeholder="5" />
                <span v-if="form.errors().has('periode_differe_en_assurance') && affiche.periode_differe_en_assurance !== 'periode_differe_en_assurance'"
                      v-text="form.errors().get('periode_differe_en_assurance')"
                      class="errorMsg"> </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-5">
                <label>Différé en TOB</label>
              </div>
              <div class="form-group col-md-6">
                <select class="form-control" 
                        name="" 
                        id=""
                        v-model="form.differe_en_tob">
                  <option value="">Choisissez une option</option>
                  <option value="OUI">Oui</option>
                  <option value="NON">Non</option>
                </select>
                <span v-if="form.errors().has('differe_en_tob') && affiche.differe_en_tob !== 'differe_en_tob'"
                      v-text="form.errors().get('differe_en_tob')"
                      class="errorMsg"> </span>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-5">
                <label>Période différée en TOB</label>
              </div>
              <div class="form-group col-md-6">
                <input type="number"
                       class="form-control"
                       name=""
                       v-model="form.periode_differe_en_tob"
                       aria-describedby="helpId"
                       v-on:click="removeSPan('periode_differe_en_tob')"
                       placeholder="5" />
                <span v-if="form.errors().has('periode_differe_en_tob') && affiche.periode_differe_en_tob !== 'periode_differe_en_tob'"
                      v-text="form.errors().get('periode_differe_en_tob')"
                      class="errorMsg"> </span>
              </div>
            </div>
            <div class="form-group mt-3">
              <button class="btn btn-f-blue mr-4"
                      type="button"
                      @click="submit"><i class="flaticon flaticon-floppy-disk"></i>&nbsp;&nbsp; Enregistrer</button>

              <button type="button"
                      class="btn btn-f-blue cancle_btn"
                      data-toggle="modal"
                      data-target="#exampleModal"><i class="flaticon flaticon-cancel"></i>&nbsp;&nbsp; Annuler</button>
            </div>
          </div>
        </div>
      </table>
    </form>
    <div class="row my-4">
      <div class="col-md-11 mx-auto">
        <!-- modal box -->
        <div class="modal fade"
             id="exampleModal"
             tabindex="-1"
             role="dialog"
             aria-labelledby="exampleModalLabel"
             aria-hidden="true">
          <div class="modal-dialog"
               role="document">
            <div class="modal-content">
              <div class="modal-header ">
                <h5 class="modal-title text-uppercase"
                    id="exampleModalLabel">Annulation</h5>
                <button type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Cette action est irréversible, êtes-vous sûr de vouloir annuler ?</p>
              </div>
              <div class="modal-footer">
                <button type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal">Non</button>
                <button type="button"
                        @click="goBack()"
                        data-dismiss="modal"
                        class="btn btn-primary">Oui</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
div ::placeholder {
  color: rgba(220, 220, 220, 0.897);
}
/* form select,
form input,
form label{
  font-size: 0.8em;
} */
.card-body input[type="text"], 
.card-body input[type="date"], 
.card-body input[type="number"], 
.card-body input[type="file"]
{
  border-radius: 5px;
  border-color:#cecece;
}
/* form, 
form input[type="text"], 
form input[type="date"], 
form input[type="number"], 
form input[type="file"], 
form textarea,
span[slot="no-options"].text-danger{
  font-size: 0.8em;
}
form select option,
form select.form-control,
input.vs__search{
  font-size: 0.7em;
} */
form input, form textarea{
  height: fit-content;
}
div.vs__dropdown-toggle,form select.form-control{
  height: 4.5vh;
}
/* @media only screen and (width > 1466px){
  div.vs__dropdown-toggle,form select.form-control{
  height: fit-content;
  }
  form select,
  form input,
  form label{
    font-size: 0.8em;
  }
  form, 
  form input[type="text"], 
  form input[type="date"], 
  form input[type="number"], 
  form input[type="file"], 
  form textarea,
  form select option,
  form select.form-control,
  span[slot="no-options"].text-danger{
    font-size: 0.7em;
  }
  input.vs__search{
    font-size: 0.8em;
  }
} */

</style>
<script>
import form from "vuejs-form"
import { mapActions, mapGetters, mapMutations } from "vuex"
import Notif from "@/components/shared/Toast"
import VSelect from "vue-select"
import 'vue-select/dist/vue-select.css'

export default {
  name: "CreateLeveeFond",
  components: {
    Notif,
    VSelect
  },
  data: () => ({
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    montant_pret: "",
    montant_tirages: "",
    garantie_necessaire: "",
    echeance_echue: "",
    ttc: "",
    tva: "",
    form: form({
      base: "",
      nature_dette: "",
      objet_dette: "",
      preteur: "",
      montant_pret: "",
      montant_tirages: "",
      date_mise_place: "",
      date_fin_remboursement: "",
      date_echeance_echue: "",
      periodicite: "",
      differe_en_principal:"NON",
      differe_en_interet:"NON",
      differe_en_tob:"NON",
      differe_en_assurence:"NON",
      duree_remboursement: "",
      taux_interet: "",
      periode_differe_en_principal: "",
      periode_differe_en_interet: "",
      periode_differe_en_tob: "",
      periode_differe_en_assurance: "",
      garantie_accordee: "NON",
      nature_garantie: "",
      echeance_echue: "",
      garantie_necessaire: "",
      ttc:"",
      tva:""
    })
      .rules({
        // nature_dette: "required",
        // objet_dette: "required",
        preteur: "required",
        montant_pret: "required",
        // montant_tirages: "required",
        date_mise_place: "required",
        date_fin_remboursement: "required",
        periodicite: "required",
        // differe_en_principal:"required",
        // differe_en_interet:"required",
        // differe_en_tob:"required",
        // differe_en_assurence:"required",
        duree_remboursement: "required",
        taux_interet: "required",
        // periode_differe_en_principal: "required",
        // periode_differe_en_interet: "required",
        // periode_differe_en_tob: "required",
        // periode_differe_en_assurance: "required",
        // garantie_accordee: "required",
        // nature_garantie: "required",
        // ttc:"required",
        // tva:"required"
      })
      .messages({
        nature_dette: "Champs requis",
        objet_dette: "Champs requis",
        preteur: "Champs requis",
        montant_pret: "Champs requis",
        montant_tirages: "Champs requis",
        date_mise_place: "Champs requis",
        date_fin_remboursement: "Champs requis",
        maturite: "Champs requis",
        periodicite: "Champs requis",
        duree_remboursement: "Champs requis",
        taux_interet: "Champs requis",
        periode_differe_en_principal: "Champs requis",
        garantie_accordee: "Champs requis",
        // ttc:"Champs requis",
        // tva:"Champs requis"
      }),
    affiche: {
      nature_dette: "nature_dette:true",
      objet_dette: "objet_dette:true",
      preteur: "preteur:true",
      montant_pret: "montant_pret:true",
      montant_tirages: "montant_tirages:true",
      date_mise_place: "date_mise_place:true",
      date_fin_remboursement: "date_fin_remboursement:true",
      periodicite: "periodicite:true",
      differe_en_principal:"differe_en_principal:true",
      differe_en_interet:"differe_en_interet:true",
      differe_en_tob:"differe_en_tob:true",
      differe_en_assurence:"differe_en_assurence:true",
      duree_remboursement: "duree_remboursement:true",
      taux_interet: "taux_interet:true",
      periode_differe_en_principal: "periode_differe_en_principal:true",
      periode_differe_en_interet: "periode_differe_en_interet:true",
      periode_differe_en_tob: "periode_differe_en_tob:true",
      periode_differe_en_assurance: "periode_differe_en_assurance:true",
      garantie_accordee: "garantie_accordees:true",
      nature_garantie: "nature_garantie:true",
      garantie_necessaire: "garantie_necessaire:true",
      echeance_echue: "echeance_echue:true",
      ttc: "ttc:true",
      tva: "tva:true"
    },
    listDesBanques:[]
  }),
  created() {
    this.listBanques()
  },
  watch: {
    banqueSansPage(){
      this.banqueSansPage.donnees.forEach(banqueSansPage =>
        this.listDesBanques.push({id:banqueSansPage.id,sigle:banqueSansPage.sigle})
      )
    },
    
    successLevee() {
      if (this.successLevee) {
        this.notif.message = this.successLevee
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setSuccess("")
            this.$router.push({ name: "LeveeDeFonds" })
          }.bind(this),
          3000
        )
      }
      if (this.errorsLevee) {
        this.notif.message = this.errorsLevee
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setErrors("")
          }.bind(this),
          3000
        )
      }
    }
  },
  computed: {
    ...mapGetters(["successLevee", "errorsLevee", "banqueSansPage"])
  },
  methods: {
    ...mapActions(["saveLeveeFonds", "listBanques"]),
    ...mapMutations(["setSuccess", "setErrors"]),
    recreate(value,key){
      var splinter = value.split(" ")
      this.form[key] = ""
      for (let index = 0; index < splinter.length; index++) {
        this.form[key] += splinter[index]
      }
    },
    submit() {
      
      if (
        !this.form
          .validate()
          .errors()
          .any()
      ) {
        this.saveLeveeFonds(this.form.data)
      } else {
        for (let afficheKey in this.affiche) {
          const tabVale = this.affiche[afficheKey].split(":")
          if (tabVale.length === 1) {
            this.affiche[afficheKey] = tabVale[0] + ":true"
          }
        }
      }
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this[key].split("")
      var res =""
      this.form[key]=""
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
            splinter[index] = ""
          }
          chaine += splinter[index]
        }
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      this[key] = chaine
      res = this[key].split(" ")
      for (let index = 0; index < res.length; index++) {
        this.form[key] += res[index]
      }
      this.form[key]=Number(this.form[key])
    },
    goBack() {
      this.$router.push({ name: "LeveeDeFonds" })
    },
    removeSPan(element) {
      for (let afficheKey in this.affiche) {
        const valeur = this.affiche[afficheKey].split(":")[0]
        if (valeur === element) {
          this.affiche[afficheKey] = valeur
        }
      }
    }
    //TODO voir comment gerer les dates une fois on connais les specifications
  }
}
</script>
